import { DataGridContext } from '$components/UI/DataGridCompound/DataGridCompound'
import { useCallback, useContext } from 'react'
import clsx from 'clsx'
import { TabCode } from '$lib/leads/statused/public/tab-codes.const'

export const Body = () => {
  const {
    data: { rows: managers },
  } = useContext(DataGridContext)

  const getDealStatusColorClasses = useCallback(
    (dealStatusInfo) => {
      const amountIsPositive =
        (dealStatusInfo &&
          dealStatusInfo.amount &&
          dealStatusInfo.amount > 0) ||
        false
      const amountIsNegative =
        (dealStatusInfo &&
          dealStatusInfo.amount &&
          dealStatusInfo.amount < 0) ||
        false

      return {
        'table--green': amountIsPositive,
        'table--red': amountIsNegative,
      }
    },
    [managers],
  )

  const reportedStatuses = [
    [TabCode.active, 'В работе'],
    [TabCode.new, 'Новые'],
    [TabCode.pending, 'В ожидании'],
    [TabCode.prolong, 'Нужно продлять'],
    [TabCode.frozen, 'В заморозке'],
  ]

  return managers.map((manager, i) => (
    <div className="reports-managers__panel" key={`${manager.id}-${i}`}>
      <p className="reports-managers__name">
        {manager.full_name || `${manager.last_name} ${manager.name}`}
      </p>
      <table>
        <tbody>
          {reportedStatuses.map((status, ii) => (
            <tr key={`${manager.id}-${i}-${ii}`}>
              <td>{status[1]}</td>
              <td>{(manager[status[0]] && manager[status[0]].count) || 0}</td>
              <td
                className={clsx(getDealStatusColorClasses(manager[status[0]]))}
              >
                {(manager[status[0]] && manager[status[0]].amount) || 0} ₽
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="reports-managers__stats">
        <div className="reports-managers__stat-item reports-managers__stat-item--green">
          <span>Прибыль</span>
          <span>{manager.profit || 0} ₽</span>
        </div>
        <div className="reports-managers__stat-item reports-managers__stat-item--red">
          <span>Убыток</span>
          <span>{manager.loss || 0} ₽</span>
        </div>
        <div className="reports-managers__stat-item reports-managers__stat-item--blue">
          <span>Эффективность</span>
          <span>{manager.effectiveness || 0} ₽</span>
        </div>
      </div>
    </div>
  ))
}
