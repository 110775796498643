import { useContext } from 'react'
import { DataGridContext } from '$components/UI/DataGridCompound/DataGridCompound'
import EffectorContextProvider from '$components/UI/React/EffectorContextProvider'
import { EffectorServices } from '$root/utils/effector/effector-services'
import { InvoiceGridStore } from '$lib/invoices/new-effector/invoices-grid-store'

export const Body = () => {
  const {
    data: { columns, rows: invoices },
  } = useContext(DataGridContext)

  const invoicesDataGrid = EffectorServices.getService({
    service: new InvoiceGridStore({
      persistKey: 'invoices-grid',
    }),
  })

  invoicesDataGrid.useContainer()

  return (
    <EffectorContextProvider>
      <tbody>
        {invoices.map((invoice, i) => (
          <tr key={`${invoice.id}-${i}`}>
            {columns.map((column, ii) => (
              <td key={`${invoice.id}-${ii}`}>
                {invoicesDataGrid.renderColumn(invoice, column)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </EffectorContextProvider>
  )
}
