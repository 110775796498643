import { useMemo, useState } from 'react'
import clsx from 'clsx'
import { time } from '$root/utils/time'
import { formatPhone, verboseCurrency } from '$root/utils/functions'
import { router } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'
import { ContactType } from '$root/utils/constants/clients'
import { OrderStatus } from '$lib/orders/utils/order-status-const'

export const DeliveriesListItem = ({ delivery }) => {
  const [isOpen, setIsOpen] = useState(false)

  const goDetailPage = () => {
    router.get(route(Routes.mobile.detail, { id: delivery.id }))
  }

  const clientPhone = useMemo(() => {
    let phone = null
    if (delivery.client.contacts) {
      const contact = delivery.client.contacts.find(
        (contact) => contact.contact_type_code === ContactType.Phone,
      )
      if (contact) {
        phone = contact.value
      }
    }
    return phone
  }, [delivery])

  const deliveryHasProblem = delivery.status === OrderStatus.Problem
  const deliveryCompleted = delivery.status === OrderStatus.Done

  return (
    <div
      className={clsx('mobile-client', {
        open: isOpen,
        problem: deliveryHasProblem,
        completed: deliveryCompleted,
      })}
    >
      <div className="mobile-client__btn" onClick={() => setIsOpen(!isOpen)}>
        <p className="mobile-client__name">
          {(delivery.client && delivery.client.full_name) ||
            (delivery.client && delivery.client.name) ||
            ''}
          {delivery.delivery_time !== null && (
            <span className="mobile-client__time">
              {time.convertTimeByTimeString(delivery.delivery_time)}
            </span>
          )}
        </p>
        <p className="mobile-client__address">{delivery.address}</p>
      </div>
      <div className="mobile-client__body">
        {clientPhone && (
          <a
            className="mobile-client__phone"
            href={
              (delivery.client && `tel:${clientPhone}`) || 'javascript:void(0)'
            }
          >
            {formatPhone(clientPhone)}
          </a>
        )}
        <div className="mobile-client__rations">
          {delivery.rations &&
            delivery.rations.map((ration, i) => (
              <div className="mobile-client__ration" key={`${ration.id}-${i}`}>
                {ration.name || ration.ration_name}
              </div>
            ))}
        </div>
        {/*<p className="mobile-client__sum">{delivery.discount_price && verboseCurrency(delivery.discount_price) || '-'}</p>*/}
        {delivery.status_comment && (
          <p className="mobile-client__status_comment">
            Причина статуса: {delivery.status_comment}
          </p>
        )}
        {delivery.comment && (
          <p className="mobile-client__comment">
            Комментарий: {delivery.comment || ''}
          </p>
        )}
        <button
          className="btn btn__filled btn--blue"
          onClick={() => goDetailPage()}
        >
          {(!deliveryHasProblem && !deliveryCompleted && <>Начать</>) || (
            <>Открыть</>
          )}
        </button>
      </div>
    </div>
  )
}
