import { usePage } from '@inertiajs/react'
import { DatePickerInput } from '$components/UI/Inputs/DatePickerInput'
import React, { useContext, useMemo } from 'react'
import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'
import { Input } from '$components/UI/Inputs/Input'
import { useDateTime } from '$hooks/useDateTime'
import { EffectorContext } from '$root/utils/effector/effector-services'
import { InvoicePayableType, InvoiceStatus } from '$lib/invoices/utils/const'
import clsx from 'clsx'
import { PaySystem } from '$root/utils/constants/payment'

const InvoiceEditableListItem = ({ actions, paySystems: onlyPaySystems }) => {
  const { enums = {} } = usePage().props
  let { paySystems, invoiceStatuses } = enums
  const { dateToSaveFormat } = useDateTime()

  const { formService } = useContext(EffectorContext)

  const form = formService.form

  const editable = useMemo(() => {
    const allow = {
      status: true,
      date_bill: true,
      pay_system: true,
      amount: true,
    }

    if (form.data.payable_type === InvoicePayableType.Order) {
      allow.date_bill = false
      allow.amount = false
    }

    return allow
  }, [form.data])

  if (paySystems[PaySystem.Inner]) {
    delete paySystems[PaySystem.Inner]
  }

  if (onlyPaySystems) {
    paySystems = _.keys(paySystems).reduce((res, paySystemCode) => {
      if (onlyPaySystems.includes(paySystemCode)) {
        res[paySystemCode] = paySystems[paySystemCode]
      }
      return res
    }, {})
  }

  return (
    <div className="invoice-body invoice-body__editable">
      <div className="status">
        <DropdownBtn
          disabled={!form.data.id}
          items={invoiceStatuses}
          titleKey="value"
          valueKey="key"
          needEmpty={false}
          value={(form.data.id && form.data.status) || InvoiceStatus.Pending}
          onChange={(selected) => {
            formService.setFieldValue(
              'status',
              (selected && selected.key) || null,
            )
          }}
          error={formService.store.markErrors && form.errors.status}
        />
      </div>

      <div
        className={clsx('issued-at', {
          error: formService.store.markErrors && form.errors.date_bill,
        })}
      >
        <DatePickerInput
          disabled={!editable.date_bill}
          value={form.data.date_bill}
          onChange={(date) =>
            formService.setFieldValue('date_bill', dateToSaveFormat(date))
          }
        />
      </div>
      <div className="pay-type">
        <DropdownBtn
          disabled={!editable.pay_system}
          items={paySystems}
          titleKey="value"
          needEmpty={false}
          valueKey="key"
          value={form.data.pay_system}
          onChange={(selected) => {
            formService.setFieldValue(
              'pay_system',
              (selected && selected.key) || null,
            )
          }}
          error={formService.store.markErrors && form.errors.pay_system}
        />
      </div>
      <div className="issued-at">
        <Input
          disabled={!editable.amount}
          type="number"
          step="0.01"
          placeholder="Сумма"
          value={form.data.amount}
          onChange={(ev) => {
            formService.setFieldValue(
              'amount',
              (ev.target.value &&
                !isNaN(ev.target.value) &&
                parseFloat(ev.target.value)) ||
                0,
            )
          }}
          error={formService.store.markErrors && form.errors.amount}
        />
      </div>
      {actions}
    </div>
  )
}

export default InvoiceEditableListItem
