import React, { useMemo } from 'react'
import { TagsDropdown } from '$components/UI/Inputs/TagsDropdown'
import { usePage } from '@inertiajs/react'

export const Ration = ({ client, onChange, routes, disabled = true, name }) => {
  const { enums } = usePage().props
  const { rations } = enums
  const clientRations = client.rations

  const titleKey = useMemo(() => {
    return (rationInfo) => {
      if (!rationInfo) {
        return
      }
      return rationInfo.name ? rationInfo.name : rationInfo.ration_name
    }
  }, [])

  const onUpdateRations = (tags) => {
    onChange(tags)
  }

  const onChangeRationReplace = (
    ration,
    currentReplace,
    newReplacement,
    isDelete = false,
  ) => {
    if (!ration.replacements) {
      ration.replacements = []
    }

    if (isDelete) {
      const index = ration.replacements.findIndex(
        (replace) => replace == currentReplace,
      )
      ration.replacements.splice(index, 1)
    } else {
      if (newReplacement.multiplier_id && !newReplacement.multiplier_code) {
        newReplacement.multiplier_code =
          enums.multipliers[newReplacement.multiplier_id].code
      }
      if (!currentReplace) {
        ration.replacements.push(newReplacement)
      } else {
        const index = ration.replacements.findIndex(
          (replacement) => replacement === currentReplace,
        )
        if (index >= 0) {
          ration.replacements.splice(index, 1, newReplacement)
        }
      }
    }

    onUpdateRations([...clientRations.map((ration) => ({ ...ration }))])
  }

  return (
    <div className="modal-right__tags">
      <TagsDropdown
        variants={rations}
        titleKey={titleKey}
        values={clientRations}
        onChange={onUpdateRations}
        disabled={disabled}
        needEmpty={false}
        uniqFn={(variants_, activeTags_, value) => {
          return ![...(activeTags_ || [])]
            .map((tag_) => tag_.id)
            .includes(value.id)
        }}
      >
        {(activeRations) => {
          return (
            <>
              {(activeRations || []).map((activeRation, i) => {
                let persistKey = `${client.id}-${activeRation.client_ration_id || activeRation.id}-${i}`

                return (
                  <TagsDropdown.ActiveTagDropdown
                    className="ration-item"
                    tag={activeRation}
                    key={persistKey}
                    persistKey={persistKey}
                  >
                    <TagsDropdown.ActiveTagRationReplacer
                      order={client}
                      ration_id_key="client_ration_id"
                      ration={activeRation}
                      routes={routes}
                      onChangeCb={onChangeRationReplace}
                    />
                  </TagsDropdown.ActiveTagDropdown>
                )
              })}
              {!disabled && (
                <TagsDropdown.AddNewTag
                  items={rations.map((ration) => ({
                    ...ration,
                    ration_id: ration.id,
                    id: undefined,
                  }))}
                />
              )}
            </>
          )
        }}
      </TagsDropdown>
    </div>
  )
}
