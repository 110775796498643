export const SalariesDetail = ({ salaries, isOpen }) => {
  return (
    isOpen && (
      <div className="reports-stats__details">
        <table>
          <thead>
            <tr>
              <th>Отдел</th>
              <th>Зарплата</th>
              <th>Премия</th>
              <th>Штраф</th>
              <th>Итог</th>
            </tr>
          </thead>
          <tbody>
            {salaries.map((salary, i) => (
              <tr key={`${salary.id}-${i}`}>
                <td>{salary.department || '-'}</td>
                <td>{salary.salary || 0}</td>
                <td>{salary.awards || 0}</td>
                <td>{salary.penalty || 0}</td>
                <td>{salary.total || 0}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  )
}
