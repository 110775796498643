import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'
import { useStore } from 'effector-react'
import { $dataGrid, dataGridIsChanged } from '$models/dataGrid'
import { deferred } from '$root/utils/functions'
import clsx from 'clsx'

export const DropdownFilter = ({
  field,
  variants,
  titleKey,
  needEmpty,
  className,
  valueKey = 'id',
  emptyText = 'Не выбрано',
  multiple = false,
  values = undefined,
}) => {
  const dataGrid = useStore($dataGrid)
  const { filter } = dataGrid

  const deferredFilter = deferred((options) => {
    dataGridIsChanged({ filter: options.filter })
  })

  let value = undefined

  if (!_.isEmpty(values)) {
    value = values
  } else if (!_.isEmpty(filter) && filter[field]) {
    value = filter[field]
  } else {
    value = multiple ? [] : null
  }

  return (
    <>
      <DropdownBtn
        className={clsx('min-width', className)}
        items={variants}
        multiple={multiple}
        value={value}
        emptyText={emptyText}
        titleKey={titleKey}
        valueKey={valueKey}
        needEmpty={needEmpty}
        onChange={(selected) => {
          let filtering = (selected && selected[valueKey]) || null

          if (multiple) {
            filtering = selected.map((selectedItem) => selectedItem[valueKey])
          }

          // TODO: refactor
          if (_.isArray(filtering) ? filtering.length : selected) {
            deferredFilter(
              {
                filter: { ...(filter || {}), [field]: filtering },
              },
              multiple ? 1.5 : 0,
            )
          } else {
            if (filter) {
              delete filter[field]
            }
            deferredFilter(
              {
                filter: { ...(filter || {}) },
              },
              multiple ? 1.5 : 0,
            )
          }
        }}
      />
    </>
  )
}
