import React, { useContext } from 'react'
import { ClientContactsContext } from '$components/Clients/Fields/Contacts/ClientContacts'
import { ContactType } from '$root/utils/constants/clients'
import { formatPhone } from '$root/utils/functions'

export const AllContacts = () => {
  const { client } = useContext(ClientContactsContext)

  const orderedContactTypes = () => {
    return [
      {
        title: 'Телефон',
        contactType: ContactType.Phone,
      },
      {
        title: 'Телеграм',
        contactType: ContactType.Telegram,
      },
      {
        title: 'WhatsApp',
        contactType: ContactType.WhatsApp,
      },
      {
        title: 'Вконтакте',
        contactType: ContactType.Vk,
      },
      {
        title: 'Instagram',
        contactType: ContactType.Instagram,
      },
      {
        title: 'Viber',
        contactType: ContactType.Viber,
      },
    ]
  }

  const formatContactValue = (contact) => {
    if (contact.contact_type_code === ContactType.Phone) {
      const formattedPhone = formatPhone(contact.value)
      if (formattedPhone) return formattedPhone
    }
    return contact.value
  }

  let firstPhone = false

  const additionalContactInfo = (contact) => {
    if (contact.contact_type_code === ContactType.Phone) {
      if (!firstPhone) {
        contact.is_first = true
        firstPhone = true
      }
      return contact
    }
  }

  return orderedContactTypes().map((ordered, i) => (
    <React.Fragment key={`${ordered.title}-${i}`}>
      {client.contacts
        .filter((contact) => contact.contact_type_code === ordered.contactType)
        .map(additionalContactInfo)
        .map((contact, i) => (
          <tr key={`${ordered.title}${i}`}>
            <td>{ordered.title}</td>
            <td>
              {(contact.is_first && (
                <strong>{formatContactValue(contact)} - Основной</strong>
              )) ||
                formatContactValue(contact)}
            </td>
          </tr>
        ))}
    </React.Fragment>
  ))
}
