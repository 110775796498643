import { router, usePage } from '@inertiajs/react'
import React, { useCallback, useEffect } from 'react'
import { z, ZodError } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { verboseCurrency } from '$root/utils/functions'
import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'
import { NoResults } from '$components/UI/DataGrid/NoResults'
import { Input } from '$components/UI/Inputs/Input'
import { Routes } from '$root/utils/constants/routes'
import { Form, FormProvider, useForm } from 'react-hook-form'
import { MessageModal } from '$components/UI/Modals/MessageModal'
import { useModals } from '$hooks/useModals'
import clsx from 'clsx'

type VerboseName = string

type SalaryCurrenciesEnum = {
  day: VerboseName
  delivery: VerboseName
  hour: VerboseName
  package: VerboseName
}

type CurrencyCode = 'day' | 'hour' | 'package' | 'delivery'

type SalaryRate = {
  role_id: number
  role_name: 'manager' | 'courier' | 'cook' | 'packer'
  role_title: string
  currency: CurrencyCode
  amount: string
}

type PageProps = {
  enums: { salaryCurrencies: SalaryCurrenciesEnum }
  salary_rates: SalaryRate[]
}

type FormType = {
  checkedRate?: SalaryRate | null
  currency?: SalaryRate['currency'] | null
  amount: number
  role_id?: number
}

const formSchema = z.object({
  currency: z.string({ message: 'Не выбран тип ставки' }),
  amount: z.number({ message: 'Введите сумму' }),
  role_id: z.number({ message: 'Нужно отметить изменяемую ставку' }),
  checkedRate: z
    .object({
      role_id: z.number(),
    })
    .catchall(z.any())
    .nullable()
    .optional(),
})

export const SettingsFinancePayment = () => {
  const { openModal } = useModals()
  const { enums, salary_rates } = usePage<PageProps>().props

  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      checkedRate: null,
      currency: null,
      amount: undefined,
    },
    reValidateMode: 'onChange',
  })

  const {
    control,
    watch,
    formState: { errors, isValid },
    setValue,
  } = form

  const data = watch()

  useEffect(() => {
    let newCurrency = null
    if (data.checkedRate) {
      newCurrency = data.checkedRate.currency
    }
    if (newCurrency == null && data.currency == null) return
    setValue('currency', newCurrency, { shouldValidate: true })
  }, [data.checkedRate])

  const preSend = (sendingData: FormType) => {
    const checkedRate = sendingData['checkedRate']
    if (sendingData['checkedRate']) {
      delete sendingData['checkedRate']
    }
    sendingData.role_id = checkedRate?.role_id
    return sendingData
  }

  const submit = ({ data }: { data: FormType }) => {
    const sendingData = preSend(data)

    try {
      const validData = formSchema.parse(sendingData)

      if (validData) {
        router.post(route(Routes.settings.finance.payment.save), validData)
      }
    } catch (e: unknown) {
      if (_.isError(e)) {
        alert(e.toString())
      }
    }
  }

  form.register('currency', { shouldUnregister: true })
  const { ref, ...amountProps } = form.register('amount')

  const checkErrors = useCallback(() => {
    try {
      formSchema.parse(data)
    } catch (e: unknown) {
      if (e instanceof ZodError) {
        const errorMessages = _.values(e.errors).reduce((acc, error) => {
          if (error.path.length > 0) {
            console.log(error.message)
            acc.push(error.message)
          }
          return acc
        }, [] as string[])

        if (errorMessages.length > 0) {
          openModal('FormValidationError', MessageModal, {
            permit: true,
            title: 'Данные формы некорректны!',
            messages: errorMessages,
          })
        }
      } else {
        if (_.isError(e)) {
          alert(e.toString())
        }
      }

      // ev.preventDefault()
    }
  }, [data])

  return (
    <FormProvider {...form}>
      <Form className="payment" control={control} onSubmit={submit}>
        <div className="payment__col payment__col--two-third">
          <div className="check-list">
            <p className="check-list__title">Ставки</p>
            <div className="check-list__section">
              {(salary_rates.length > 0 &&
                salary_rates.map((salary_rate, i) => (
                  <label className="check-list__item" key={i}>
                    <span className="input-chckbx">
                      <input
                        type="checkbox"
                        checked={
                          data?.checkedRate?.role_id === salary_rate.role_id
                        }
                        onChange={() =>
                          setValue(
                            'checkedRate',
                            (data.checkedRate &&
                              salary_rate &&
                              data.checkedRate.role_id !==
                                salary_rate.role_id &&
                              salary_rate) ||
                              (!data.checkedRate && salary_rate) ||
                              null,
                            { shouldValidate: true },
                          )
                        }
                      />
                      <span></span>
                    </span>
                    <span className="check-list__item-title">
                      {salary_rate.role_title}
                    </span>
                    <span className="check-list__item-inner">
                      <span className="check-list__item-type">
                        {enums.salaryCurrencies &&
                          enums.salaryCurrencies[salary_rate.currency]}
                      </span>
                      <span className="check-list__item-cost">
                        {verboseCurrency(salary_rate.amount, 0)}
                      </span>
                    </span>
                  </label>
                ))) || <NoResults title="Нет данных" />}
            </div>
          </div>
        </div>
        <div className="payment__col payment__col--one-third">
          <div className="payment__form">
            <p className="payment__form-title">Назначить оплату</p>
            <div className="payment__row">
              <label className="input">
                <input
                  type="text"
                  className={clsx({ error: !isValid && errors.role_id })}
                  placeholder={
                    (data.checkedRate && data.checkedRate.role_title) ||
                    'Не заполнено'
                  }
                  disabled
                />
              </label>
            </div>
            <div className="payment__row">
              <DropdownBtn
                items={enums.salaryCurrencies}
                titleKey="value"
                valueKey="key"
                value={data.currency}
                onChange={(selected?: {
                  currency?: CurrencyCode
                  key?: CurrencyCode
                }) => {
                  setValue(
                    'currency',
                    (selected && selected.currency) ||
                      (selected && selected.key) ||
                      null,
                    { shouldValidate: true },
                  )
                }}
                error={!isValid && errors && errors.currency}
              />
            </div>
            <div className="payment__row">
              <Input
                {...amountProps}
                type="number"
                value={data.amount}
                placeholder="Сумма"
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                  setValue('amount', _.toNumber(ev.target.value), {
                    shouldValidate: true,
                  })
                }
                error={!isValid && errors && errors.amount}
              />
            </div>
            <div className="payment__row">
              <button
                type="submit"
                className="btn btn__filled btn--blue"
                onClick={checkErrors}
              >
                Изменить
              </button>
            </div>
          </div>
        </div>
      </Form>
    </FormProvider>
  )
}
