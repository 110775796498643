import { createApi, createEvent, createStore } from 'effector'

const defaultValue = {}

export const $permissions = createStore(defaultValue)

export const permissionsApi = createApi($permissions, {
  create: (store, permissions) => permissions,
  set: (store, { path, value }) => _.set(store, path, value),
  clear: () => ({}),
})

export const saveEnabledChanged = createEvent()
export const $saveEnabled = createStore(false).on(
  saveEnabledChanged,
  (store, enabled) => enabled,
)
