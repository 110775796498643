import { NewTransaction } from '$components/Clients/TransactionHistory/NewTransaction'
import { useContext, useEffect, useMemo, useState } from 'react'
import { router, useForm, usePage } from '@inertiajs/react'
import { useModals } from '$hooks/useModals'
import { onPostSended } from '$models/page'
import clsx from 'clsx'
import { useDateTime } from '$hooks/useDateTime'
import { MessageModal } from '$components/UI/Modals/MessageModal'
import { EffectorContext } from '$root/utils/effector/effector-services'

export const TransactionTable = ({
  client,
  routes,
  transactions: clientTransactions,
  invoiceList,
  allowChange = false,
}) => {
  const { enums } = usePage().props

  const { openModal } = useModals()
  const { currentDate } = useDateTime()
  const { invoicesService } = useContext(EffectorContext)

  const [transactions, setTransactions] = useState(clientTransactions)
  const {
    data: transaction,
    setData: setTransactionData,
    reset,
    clearErrors,
  } = useForm({})

  const currencyEnum = enums.transactionCurrencies

  const issetTransactions = useMemo(() => [...transactions], [transactions])
  const issetManualTransaction = useMemo(() => {
    return issetTransactions.find((__) => __.manual === true)
  }, [issetTransactions])

  useEffect(() => {
    setTransactions(clientTransactions)
  }, [clientTransactions])

  const onAdd = (transaction) => {
    const insertingTransactions = []
    let changingTransaction =
      (issetManualTransaction && { ...issetManualTransaction }) || null

    const defaultTransaction = () => {
      changingTransaction = {
        date_create: currentDate(),
        manual: true,
      }

      for (const currencyCode in currencyEnum) {
        changingTransaction[currencyCode] = 0
      }
      return changingTransaction
    }

    const removeManualTransaction = () => {
      issetTransactions.splice(
        issetTransactions.indexOf(issetManualTransaction),
        1,
      )
    }

    const checkAmounts = () => {
      let emptyCounter = 0
      for (const currencyCode of _.keys(currencyEnum)) {
        if (!changingTransaction[currencyCode]) {
          emptyCounter++
        }
      }
      return emptyCounter < _.keys(currencyEnum).length
    }

    const convertTransactionToCurrencies = (transactionRow) => {
      const transactionsByCurrency = []

      if (!transactionRow) return transactionsByCurrency

      for (const currencyCode in currencyEnum) {
        if (transactionRow.hasOwnProperty(currencyCode)) {
          const amount = transactionRow[currencyCode]
          if (typeof amount == 'number' && !!amount) {
            transactionsByCurrency.push({
              currency: currencyCode,
              amount,
            })
          }
        }
      }
      return transactionsByCurrency
    }

    const checkManualTransaction = () => {
      if (!changingTransaction) {
        defaultTransaction()
      } else {
        removeManualTransaction()
        changingTransaction.date_create = currentDate()
      }
    }

    const updateTransactionAmount = () => {
      changingTransaction[transaction.currency] += transaction.amount
    }

    checkManualTransaction()
    updateTransactionAmount()

    if (checkAmounts()) {
      insertingTransactions.push(changingTransaction)
    } else {
      changingTransaction = null
    }

    setTransactions([...insertingTransactions, ...issetTransactions])
    setTransactionData(convertTransactionToCurrencies(changingTransaction))
  }

  const clearRow = () => {
    return setTransactions([...transactions].filter((__) => __.manual !== true))
  }

  const issetTransaction = () => {
    return _.keys(transaction).length
  }

  const saveTransaction = async () => {
    await new Promise((resolve) => {
      if (!issetTransaction()) {
        if (invoicesService.edited.length === 0 && invoicesService.validate()) {
          openModal('TransactionFormError', MessageModal, {
            title: 'Транзакций не было.',
          })
        }

        resolve()
      } else {
        for (const tx of transaction) {
          router.post(route(routes.transactions.save, route().params), tx, {
            onSuccess() {
              reset()
              clearRow()
              clearErrors()
              onPostSended()
            },
            onFinish() {
              resolve()
            },
          })
        }
      }
    })

    const showErrorInvoiceForms = (isErrorNumbers) => {
      openModal('TransactionFormInvoicesError', MessageModal, {
        title: 'Формы счетов заполнены некорректно',
      })
    }

    invoicesService.save(showErrorInvoiceForms)
  }

  const TransactionRows = () => {
    return transactions.map((transaction, i) => (
      <tr key={`${transaction.date_create}-${transaction.ruble}-${transaction.bonus}-${i}`} className={clsx({ inserting: transaction.manual === true })}>
        {/*TODO: заменить на useDateTime */}
        <td>
          {transaction.date_create
            ? new Date(transaction.date_create).toLocaleDateString()
            : ''}
        </td>

        {_.keys(currencyEnum).map((currencyCode, i) => (
          <th key={`${currencyCode}-${i}`}>
            {transaction[currencyCode] ? transaction[currencyCode] : 0} ₽
          </th>
        ))}
      </tr>
    ))
  }

  return (
    <>
      <div className="modal-right__section">
        <table className="balance-history">
          <tbody>
            <tr>
              <th>Дата</th>
              {_.keys(currencyEnum).map((currencyCode, i) => (
                <th key={`${currencyCode}-${i}`}>{currencyEnum[currencyCode]}</th>
              ))}
            </tr>

            <TransactionRows />
          </tbody>
        </table>
      </div>
      {invoiceList}
      {allowChange && (
        <NewTransaction
          client={client}
          onAdd={onAdd}
          onSave={saveTransaction}
        />
      )}
    </>
  )
}
