import { useContext } from 'react'
import { DataGridContext } from '$components/UI/DataGridCompound/DataGridCompound'
import { CourierFinancesCollapse } from '$components/Reports/DataGrids/Couriers/Finances/CourierFinancesCollapse'

export const Body = () => {
  const {
    data: { rows: couriers },
  } = useContext(DataGridContext)

  return (
    <tbody>
      {couriers.map((courier, i) => (
        <CourierFinancesCollapse key={`${courier.id}-${i}`} courier={courier} />
      ))}
    </tbody>
  )
}
