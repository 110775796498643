import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import { forwardRef, useState } from 'react'
import clsx from 'clsx'
import { useDateTime } from '$hooks/useDateTime'

const WeekDays = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
registerLocale('ru', {
  ...ru,
  localize: { ...ru.localize, day: (n) => WeekDays[n] },
})
setDefaultLocale('ru')

export const DatePickerInput = ({
  value,
  onChange,
  multiple,
  inline,
  ShowPickerComponent,
  showPickerTag = false,
  excludeDates = [],
  disabled,
  error,
  label = undefined,
  ...props
}) => {
  const { date } = useDateTime()
  const datedValue = _.isDate(value)
    ? value
    : Array.isArray(value)
      ? value
      : (value && new Date(value)) || null
  const initialValues =
    Array.isArray(datedValue) && datedValue.length > 1
      ? [new Date(datedValue[0]), new Date(datedValue[1])]
      : [datedValue, null]
  const [startDate, setStartDate] = useState(initialValues[0])
  const [endDate, setEndDate] = useState(initialValues[1])
  const [isOpen, setIsOpen] = useState(false)

  if (!ShowPickerComponent) {
    ShowPickerComponent = forwardRef((props, ref) => (
      <span
        {...props}
        onClick={(ev) => {
          // react-datepicker-ignore-onclickoutside
          if (props.onClick) {
            setIsOpen(true)
            props.onClick(ev)
          }
        }}
        className="flex align-items-center date-picker-value-badge"
      >
        {(!multiple && props.value && !showPickerTag && (
          <>
            <span
              onClick={() => {
                if (isOpen) {
                }
              }}
            >
              {(!label && date(props.value)) || label}
            </span>
            <button
              {...props}
              className={clsx('table-plan__btn-calendar', props.className)}
              ref={ref}
            />
          </>
        )) || (
          <>
            {label}
            <button
              {...props}
              className={clsx('table-plan__btn-calendar', props.className)}
              ref={ref}
            />
          </>
        )}
        {props.value && showPickerTag && (
          <>
            <div className="deal-head__tags">
              <button
                className="select-tag"
                onClick={(ev) => {
                  onSelectDate((multiple && [null, null]) || null)
                  onChange(null)
                  ev.preventDefault()
                  ev.stopPropagation()
                }}
              >
                {props.value}
              </button>
            </div>
          </>
        )}
      </span>
    ))
  }

  const onSelectDate = (date) => {
    setIsOpen(false)
    if (multiple) {
      const [start, end] = date
      setStartDate(start)
      setEndDate(end)
    } else {
      setStartDate(date)
    }
    if (onChange) {
      onChange(multiple ? (!date[0] && !date[1] ? null : date) : date)
    }
  }

  return (
    <DatePicker
      locale="ru"
      className={clsx({ error })}
      popperClassName={clsx(props.popperClassName || {}, {
        multiple,
      })}
      selected={startDate}
      onChange={onSelectDate}
      dateFormat="yyyy-MM-dd"
      startDate={startDate}
      endDate={endDate}
      excludeDates={excludeDates}
      selectsRange={multiple}
      inline={inline}
      customInput={<ShowPickerComponent />}
      disabled={disabled}
      {...props}
    />
  )
}
