export const HeaderMultipliersList = ({ multipliers }) => {
  return (
    <tr>
      <th>Порция</th>
      {multipliers.map((multiplier, i) => (
        <th key={`${multiplier.id}-${i}`}>{multiplier.name}</th>
      ))}
      <th></th>
    </tr>
  )
}
