import { OrderFieldComponentsByStatus } from '$lib/orders/utils/const'

export const OrdersFields = (props) => {
  const { status } = props
  const Fields = OrderFieldComponentsByStatus[status]

  return Fields.map((Field, i) => (
    <td key={`${props.order.id}-${status}-${i}`}>
      {Field && <Field {...props} />}
    </td>
  ))
}
