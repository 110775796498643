import { StaffTransactionsDropdown } from '$components/Staff/Transactions/StaffTransactionsDropdown'
import { useState } from 'react'
import { useEffectorDomain } from '$hooks/useEffectorDomain'
import { SalaryTransactionsEffector } from '$lib/staff/effector/staff-transactions'
import { router } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'
import { useModals } from '$hooks/useModals'
import { MessageModal } from '$components/UI/Modals/MessageModal'

export const StaffTransactions = ({
  users = [],
  salaryRates = null,
  routes,
}) => {
  if (!salaryRates) return <>Ошибка...</>
  if (users.length === 0)
    return <>Сотрудники с необходимой должностью не найдены</>

  const { service: salaryService } = useEffectorDomain(
    new SalaryTransactionsEffector(),
  )

  salaryService.useContainer()
  salaryService.useStores()

  const { openModal, closeModal } = useModals()

  const [dataTransitionsCallbacks, setDataTransitionCallback] = useState({})

  const transaction_types = {
    bonus: {
      title: 'Премия',
      multiplier: 1,
    },
    penalty: {
      title: 'Штраф',
      multiplier: -1,
    },
  }

  const saveTransitions = () => {
    const transactions = salaryService.getTransactions()
    if (transactions.length > 0) {
      router.post(
        route(routes.save),
        { transactions },
        {
          onSuccess() {
            closeModal(false)
            openModal('StaffTransactionsSuccess', MessageModal, {
              title: 'Данные успешно сохранены',
            })
            salaryService.reset()
          },
        },
      )
    }
  }

  return (
    <>
      <div className="modal-right__section">
        {users.map((user, i) => (
          <>
            <StaffTransactionsDropdown
              disabled
              key={`${user}-${i}`}
              user={user}
              getFormDataCallback={(dataCallback) =>
                setDataTransitionCallback({
                  ...dataTransitionsCallbacks,
                  [user.id]: dataCallback,
                })
              }
              transaction_currencies={salaryRates}
              transaction_types={transaction_types}
              customTable={user.customTable}
              onChange={salaryService.updateTransactions.bind(salaryService)}
            />
          </>
        ))}
      </div>
      <div className="modal-right__section">
        <button
          className="btn btn__filled btn--green"
          onClick={saveTransitions}
        >
          Сохранить
        </button>
      </div>
    </>
  )
}
