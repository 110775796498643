import { useMemo } from 'react'
import { useDateTime } from '$hooks/useDateTime'

export const RationsField = ({ rations, last_delivery }) => {
  const { date } = useDateTime()

  const RationsList = useMemo(() => {
    return (rations || []).map((ration, i) => (
      <a className="deal__ration" href="#" key={`${ration.id}-${i}`}>
        {ration.name || ration.ration_name}
      </a>
    ))
  }, [rations])

  return (
    <div className="deal__freeze-inner">
      {RationsList}
      <p className="deal__freeze-date">
        Последняя доставка &nbsp;
        <span>{(last_delivery && date(last_delivery)) || '-'}</span>
      </p>
    </div>
  )
}
