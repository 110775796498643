import React, { useState } from 'react'
import clsx from 'clsx'
import { permissionsApi, saveEnabledChanged } from './models'

export const AccessItem = ({ type, permissionName, currentValue }) => {
  const [accessValue, setAccessValue] = useState(currentValue)
  const [dropDownIsOpen, setDropDownIsOpen] = useState(false)

  const onDropdownItemClick = (value) => {
    setAccessValue(value)
    setDropDownIsOpen(!dropDownIsOpen)

    permissionsApi.set({
      path: _.join([permissionName, type], '.'),
      value: value,
    })

    saveEnabledChanged(true)
  }

  return (
    <div className={clsx('dropdown js-dropdown', { open: dropDownIsOpen })}>
      <button
        className="dropdown__btn dropdown__btn--hidden js-dropdown-btn"
        onClick={(e) => setDropDownIsOpen(!dropDownIsOpen)}
      >
        {accessValue ? 'Да' : 'Нет'}
      </button>
      <div className="dropdown__list">
        <button
          className="dropdown__list-item"
          onClick={(e) => onDropdownItemClick(true)}
        >
          Да
        </button>
        <button
          className="dropdown__list-item"
          onClick={(e) => onDropdownItemClick(false)}
        >
          Нет
        </button>
      </div>
    </div>
  )
}
