import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import { DropdownBtnActiveItem } from '$components/UI/Inputs/DropdownBtn/DropdownBtnActiveItem'
import { useOutsideClick } from '$hooks/useOutsideClick'

export const DropdownBtn = ({
  items: variants,
  titleKey = 'name',
  valueKey = 'id',
  emptyText = 'Не заполнено',
  noItemsText = 'Список пуст',
  value,
  onChange,
  error,
  blockClasses = {
    wrapper: '',
    current: '',
    list: '',
    item: '',
  },
  className = undefined,
  disabled = false,
  needEmpty = true,
  appendFirst = false,
  multiple = false,
  ActiveItemComponent = DropdownBtnActiveItem,
  blueScheme = true,
  activeItem = null,
  needTitle = true,
  name = undefined,
}) => {
  const empty = []
  // const form = useFormContext()
  // let controller = useController({ control: form.control, name })

  const ref = useRef()
  const [isOpen, setIsOpen] = useState(false)

  const listedVariants = useMemo(
    () =>
      (_.isArray(variants) && variants) ||
      [..._.entries(variants)].map((item) => ({
        key: item[0],
        value: item[1],
      })),
    [variants],
  )

  const itemTitle = useCallback(
    (item = undefined) => {
      return _.isFunction(titleKey)
        ? (!!item && titleKey(item)) || emptyText
        : (item && (item[titleKey] || (item.value && item.value[titleKey]))) ||
            emptyText
    },
    [listedVariants],
  )

  if (needEmpty) {
    empty.push({
      [valueKey]: null,
      [itemTitle()]: (!listedVariants.length && noItemsText) || emptyText,
    })
  }

  const [items, setItems] = useState([...empty, ...listedVariants])

  const isActive = (list, checkingItem) => {
    return (
      list.find((anyItem) => {
        const anyFormatted = _.isObject(anyItem)
          ? (!!anyItem[valueKey] && anyItem[valueKey].toString()) || null
          : !!anyItem && anyItem.toString()
        const checkingItemFormatted = _.isObject(checkingItem)
          ? (!!checkingItem[valueKey] && checkingItem[valueKey].toString()) ||
            null
          : checkingItem

        return (
          anyFormatted === checkingItemFormatted ||
          (!!checkingItemFormatted &&
            anyFormatted === checkingItemFormatted.toString())
        )
      }) || null
    )
  }

  const initialActiveItems = useMemo(() => {
    let _activeItems = []
    if (value !== undefined) {
      let checkedItems = []
      if (multiple) {
        checkedItems = value
          .map((checkedValue) => {
            return isActive(items, checkedValue)
          })
          .filter((x) => x !== null)
      } else {
        const checkedItem = isActive(items, value)
        if (checkedItem) {
          checkedItems.push(checkedItem)
        }
      }
      if (checkedItems.length) {
        _activeItems = checkedItems
      }
    } else if (!multiple && items.length > 0) {
      _activeItems = ((needEmpty || appendFirst) && [items[0]]) || []
    }

    return _activeItems
  }, [items, value])

  const [activeItems, setActiveItems] = useState(initialActiveItems)

  let classes = multiple
    ? {
        wrapper: (blockClasses && blockClasses.wrapper) || [
          'dropdown-multiple js-dropdown',
          {
            'dropdown-multiple--no-check': !multiple,
            // "dropdown-multiple": !multiple,
          },
        ],
        current:
          (blockClasses && blockClasses.current) ||
          'dropdown-multiple__btn js-dropdown-btn',
        list: (blockClasses && blockClasses.list) || 'dropdown-multiple__list',
        item:
          (blockClasses && blockClasses.item) || 'dropdown-multiple__list-item',
      }
    : {
        wrapper:
          (blockClasses && blockClasses.wrapper) || 'dropdown js-dropdown',
        current:
          (blockClasses && blockClasses.current) ||
          'dropdown dropdown__btn js-dropdown-btn',
        list: (blockClasses && blockClasses.list) || 'dropdown__list',
        item: (blockClasses && blockClasses.item) || 'dropdown__list-item',
      }

  if (blueScheme) {
    classes = {
      wrapper: (blockClasses && blockClasses.wrapper) || [
        'dropdown-multiple js-dropdown',
        {
          'dropdown-multiple--no-check': !multiple,
          // "dropdown-multiple": !multiple,
        },
      ],
      current:
        (blockClasses && blockClasses.current) ||
        'dropdown-multiple__btn js-dropdown-btn',
      list: (blockClasses && blockClasses.list) || 'dropdown-multiple__list',
      item:
        (blockClasses && blockClasses.item) || 'dropdown-multiple__list-item',
    }
  }
  const toggleItems = useCallback(() => {
    if (!isOpen) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
    // const element = target ? target : e.target
    // if (e) {
    //     e.preventDefault()
    // }
    // element.closest('.js-dropdown').classList.toggle('open')
  }, [isOpen])

  const onSelectItem = (e, selectedItem) => {
    if (!multiple) {
      toggleItems(null, ref.current)
    }
    selectItem(selectedItem)
  }

  const sortCheckedItems = (checkedItems) => {
    checkedItems.sort(function (a, b) {
      return activeItems.indexOf(a) - activeItems.indexOf(b)
    })
  }

  const selectItem = (selectedItem) => {
    let newItems = multiple ? [...activeItems] : []
    if (multiple && selectedItem[valueKey] === null) {
      newItems = []
    }
    const checked = multiple
      ? isActive(activeItems, selectedItem[valueKey])
      : false

    if (checked) {
      newItems.splice(activeItems.indexOf(selectedItem), 1)
    } else {
      newItems.push(selectedItem)
    }

    sortCheckedItems(newItems)
    setActiveItems(newItems)

    if (onChange) {
      onChange(
        multiple
          ? newItems
          : needEmpty && selectedItem[valueKey] === null
            ? null
            : newItems[0],
      )
    }
  }

  const initValue = useCallback(() => {
    setActiveItems(initialActiveItems)
  }, [initialActiveItems])

  const initVariants = () => {
    setItems([...empty, ...listedVariants])
  }

  useEffect(initValue, [value])
  useEffect(initVariants, [listedVariants])

  useOutsideClick(ref, () => setIsOpen(false))
  // useEffect(() => {
  //     const clickOtherCallback = (ev) => {
  //         if (ref.current && !ref.current.contains(ev.target) && ref.current.classList.contains('open')) {
  //             toggleItems(null, ref.current)
  //         }
  //     }
  //     document.addEventListener('click', clickOtherCallback)
  //
  //     return () => document.removeEventListener('click', clickOtherCallback)
  // }, [])

  return (
    <div
      className={clsx(classes.wrapper, className, {
        open: !!isOpen,
      })}
      ref={ref}
    >
      <ActiveItemComponent
        classes={classes}
        toggleItems={toggleItems}
        error={error}
        disabled={disabled}
        activeItems={activeItems}
        multiple={multiple}
        itemTitle={itemTitle}
        emptyText={emptyText}
        items={items}
        activeItem={activeItem}
        needTitle={needTitle}
      />
      <div className={clsx(classes.list)}>
        {items.map((item, i) => (
          <button
            type="button"
            key={`${item[valueKey]}-${i}`}
            className={clsx(classes.item, {
              active: isActive(activeItems, item[valueKey]) !== null,
            })}
            onClick={(e) => {
              onSelectItem(e, item)
            }}
          >
            {itemTitle(item)}
          </button>
        ))}
      </div>
    </div>
  )
}
