import { DropdownFilter } from '$components/UI/DataGrid/Filters/Dropdown'
import { usePage } from '@inertiajs/react'
import { useStore } from 'effector-react'
import { $dataGrid } from '$models/dataGrid'
import { useMemo } from 'react'

export const RationFilter = ({ field }) => {
  const { enums } = usePage().props

  const dataGrid = useStore($dataGrid)
  const { filter } = dataGrid

  let filterValues = useMemo(() => {
    if (filter && filter[field]) {
      return filter[field].map((id) => {
        return enums.rations.find(
          (iterRation) => iterRation.id.toString() === id.toString(),
        )
      })
    } else {
      return []
    }
  }, [enums.rations, filter && filter[field]])

  return (
    <DropdownFilter
      field={field}
      variants={enums.rations || []}
      values={filterValues}
      titleKey="name"
      emptyText="Рацион"
      needEmpty={false}
      multiple={true}
    />
  )
}
