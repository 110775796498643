import React, { useState } from 'react'
import { TagsDropdown } from '$components/UI/Inputs/TagsDropdown'

export const ResponsibleSelect = ({
  users,
  onChange,
  isError,
  needAdd = true,
}) => {
  const [selectedUser] = useState(null)

  return (
    <div className="mb-1">
      <TagsDropdown
        variants={users || []}
        values={(selectedUser && [selectedUser]) || null}
        titleKey="full_name"
        multiple={false}
        onChange={(selectedUsers) => {
          onChange(_.first(selectedUsers).id)
        }}
      >
        {(activeItems) => {
          return (
            <>
              {activeItems.map((activeItem, i) => (
                <TagsDropdown.SearcheableActiveItem
                  tag={activeItem}
                  key={i}
                  style={{ cursor: 'pointer' }}
                  className={isError ? 'error' : undefined}
                  prefix={false}
                >
                  <TagsDropdown.SearcheableList key={i} ration={activeItem} />
                </TagsDropdown.SearcheableActiveItem>
              ))}
            </>
          )
        }}
      </TagsDropdown>
    </div>
  )
}

export const SelectResponsibleManagerForm = ({ users, onSelect }) => {
  return (
    <>
      <div className="manager-list">
        <ResponsibleSelect users={users} onChange={onSelect} />
      </div>
    </>
  )
}
