import { useModals } from '$hooks/useModals'
import { Modal } from '$components/UI/Modals/Modal'
import { EditDeliveryInfo } from '$components/Clients/Orders/OrdersByStatus/Fields/EditDeliveryInfo'
import React, { useMemo } from 'react'
import { time } from '$root/utils/time'
import { usePage } from '@inertiajs/react'
import { useOrdersTable } from '$lib/orders/hooks/useOrdersTable'
import DayTypeDropdownBtn from '$components/UI/Inputs/DayTypeDropdownBtn'
import clsx from 'clsx'
import { usePersistOrderDeliveryType } from '$lib/clients/hooks'

const PICKUP_DELIVERY_TYPE = 'pickup'

export const DeliveryInfoField = ({
  order,
  allowEdit,
  onChange,
  needDayType = true,
  needTime = true,
  className = {},
}) => {
  const { orderDeliveryTypes, selectedDeliveryType } =
    usePersistOrderDeliveryType()
  const { enums = {} } = usePage().props
  const { deliveryTypes } = enums
  const { updateOrder } = useOrdersTable()
  const { openModal } = useModals()

  const deliveryTime = useMemo(() => {
    return typeof order.delivery_time === 'number'
      ? time.convertTimeByTimeString(order.delivery_time)
      : '_:_'
  }, [order])

  const showEditDeliveryInfoModal = () => {
    openModal('DeliveryOptions', Modal, {
      title: 'Дополнительная информация доставки',
      contentComponent: EditDeliveryInfo,
      order,
      onChange: (updatingOrders) => {
        if (onChange) {
          onChange(updatingOrders)
        } else {
          updateOrder({ order, updatingOrders })
        }
      },
      disabled: !allowEdit,
    })
  }

  const orderDeliveryType = orderDeliveryTypes[order.id] || order.delivery_type

  return (
    <>
      <div className={clsx('flex pointer', className)}>
        <button
          className="table-plan__btn-comment"
          onClick={() => allowEdit && showEditDeliveryInfoModal()}
        ></button>
        {needDayType && (
          <DayTypeDropdownBtn
            items={deliveryTypes}
            disabled={!allowEdit}
            value={orderDeliveryType}
            onChange={(selected) => {
              let updateOptions = {
                isNeedRequest: true,
                isNeedUpdateStore: false,
              }

              selectedDeliveryType({ id: order.id, deliveryType: selected.key })

              if (selected.key !== PICKUP_DELIVERY_TYPE && !order.address) {
                updateOptions = {
                  isNeedRequest: false,
                  isNeedUpdateStore: true,
                }
              }

              updateOrder({
                order,
                updatingOrders: { ...order, delivery_type: selected.key },
                ...updateOptions,
              })
            }}
          />
        )}

        {needTime && (
          <span
            className="table-plan__time"
            onClick={() => allowEdit && showEditDeliveryInfoModal()}
          >
            {deliveryTime}
          </span>
        )}
      </div>
    </>
  )
}
