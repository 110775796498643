import { usePage } from '@inertiajs/react'
import { TagsDropdown } from '$components/UI/Inputs/TagsDropdown'
import { useCallback, useMemo } from 'react'
import { useOrdersTable } from '$lib/orders/hooks/useOrdersTable'

export const RationsField = ({ order, routes, allowEdit }) => {
  const { enums } = usePage().props
  const rations = enums.rations || []
  const { updateOrder } = useOrdersTable()
  const currentRoute = useMemo(() => route().current(), [route().current()])

  const titleKey = useMemo(() => {
    return (rationInfo) => {
      if (!rationInfo) {
        return
      }
      return rationInfo.ration_name || rationInfo.name
    }
  }, [])

  const onUpdateRations = useCallback(
    (newRations) => {
      const newOrder = {
        ...order,
        rations: newRations,
      }

      updateOrder({
        order,
        updatingOrders: newOrder,
      })
    },
    [order],
  )

  return (
    <div className="table-plan__rations">
      <TagsDropdown
        variants={rations}
        titleKey={titleKey}
        values={order.rations}
        onChange={onUpdateRations}
        disabled={!allowEdit}
        needEmpty={false}
      >
        {(activeRations) => (
          <>
            {activeRations.map((activeRation, i) => {
              let persistKey = `${order.id}-${activeRation.order_ration_id || activeRation.id}-${i}-${currentRoute}`

              return (
                <TagsDropdown.ActiveTagDropdown
                  className="ration-item"
                  tag={activeRation}
                  key={persistKey}
                  persistKey={persistKey}
                >
                  <TagsDropdown.ActiveTagRationReplacer
                    order={order}
                    ration_id_key="order_ration_id"
                    ration={activeRation}
                    routes={routes.orders}
                  />
                </TagsDropdown.ActiveTagDropdown>
              )
            })}
            <TagsDropdown.AddNewTag items={rations} />
          </>
        )}
      </TagsDropdown>
    </div>
  )
}
