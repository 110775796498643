import { useCallback } from 'react'
import { Input } from '$components/UI/Inputs/Input'

export const ProductPrices = ({
  multipliers,
  product_id,
  prices,
  onChangePriceItem,
}) => {
  const getMultiplierPrice = useCallback(
    (multiplierId) => {
      const priceItem = prices.find(
        (priceItem) => priceItem.multiplier_id === multiplierId,
      )
      return parseFloat((priceItem && priceItem.price) || 0)
    },
    [prices],
  )

  const onChangePrice = (multiplierId, price) => {
    onChangePriceItem(product_id, multiplierId, price)
  }

  return multipliers.map((multiplier, i) => {
    return (
      <td key={`${multiplier.id}-${i}`}>
        <ProductPrice
          multiplierId={multiplier.id}
          productId={product_id}
          price={getMultiplierPrice(multiplier.id)}
          onChange={(price) => {
            onChangePrice(multiplier.id, price)
          }}
        />
      </td>
    )
  })
}

const ProductPrice = ({ price, onChange }) => {
  return (
    <Input
      type="number"
      placeholder="—"
      classes="input--center"
      value={price}
      onChange={(ev) => onChange(parseFloat(ev.target.value))}
    />
  )
}
