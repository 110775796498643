import { useContext, useRef, useState } from 'react'
import clsx from 'clsx'
import { TagsDropdownContext } from '$components/UI/Inputs/TagsDropdown/index'
import { useOutsideClick } from '$hooks/useOutsideClick'

export const AddNewTag = (props) => {
  const params = useContext(TagsDropdownContext)

  const { values, titleKey, tags, onAdd, uniqFn } = params

  const ref = useRef()
  const [items, setFilteredItems] = useState(tags)

  const variants = items

  const search = (searchString) => {
    if (!searchString) {
      resetSearch()
    } else {
      var re = new RegExp(_.escapeRegExp(searchString), 'i')
      setFilteredItems(
        tags.filter((tag) => {
          let isMatch = false

          if (titleKey instanceof Function) {
            isMatch = titleKey(tag).match(re)
          } else {
            isMatch = tag[titleKey].match(re)
          }

          return !!isMatch
        }),
      )
    }
  }

  const isSearching = () => {
    return variants.length !== tags.length
  }

  const resetSearch = () => {
    setFilteredItems(tags)
  }

  const toggleList = () => {
    if (ref.current.classList.contains('open')) {
      ref.current && ref.current.classList.remove('open')
    } else {
      ref.current && ref.current.classList.add('open')
    }
  }

  const onSelectItem = (item) => {
    if (onAdd(item)) {
      toggleList()
    }
  }

  useOutsideClick(
    ref,
    () => ref.current && ref.current.classList.remove('open'),
  )

  return (
    <div
      className={clsx('comment-dropdown comment-dropdown--green js-dropdown')}
      ref={ref}
    >
      <button
        className="comment-dropdown__btn js-dropdown-btn"
        onClick={toggleList}
      >
        +
      </button>
      <div className="comment-dropdown__list">
        <div className="comment-dropdown__list-inner">
          {variants
            .filter((item_) =>
              uniqFn ? uniqFn(variants, values, item_) : true,
            )
            .map((tag, i) => (
              <button
                className="comment-dropdown__list-item"
                onClick={() => onSelectItem(tag)}
                key={i}
              >
                {titleKey instanceof Function ? titleKey(tag) : tag[titleKey]}
              </button>
            ))}
        </div>
        <div className="comment-dropdown__search">
          <input
            className={clsx('comment-dropdown__search-input')}
            placeholder="Поиск"
            onInput={(ev) => {
              search(ev.target.value)
            }}
          />
          <button
            className={clsx('comment-dropdown__search-btn', {
              reset: isSearching(),
            })}
            onClick={resetSearch}
          >
            Поиск
          </button>
        </div>
      </div>
    </div>
  )
}
