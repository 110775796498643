import { DeliveriesListItem } from '$components/Mobile/DeliveriesListItem'
import { NoResults } from '$components/UI/DataGrid/NoResults'
import React from 'react'

const DemoDeliveries = [
  {
    id: 1,
    client: {
      id: 1,
      full_name: 'Сыроежкина Надя',
      phone: '+796587552',
    },
    address: {
      address: 'Адрес доставки',
      point: null,
    },
    rations: [
      {
        id: 1,
        name: 'Fit',
      },
    ],
    discount_price: 100,
    comment: '',
  },
]

export const DeliveriesList = ({ deliveries = DemoDeliveries }) => {
  return (
    <div className="mobile-client__wrap">
      {deliveries.length === 0 && (
        <NoResults
          text="Нет данных"
          title="Список пуст"
          className="mobile-client__not-found"
        />
      )}

      {deliveries.map((delivery, i) => (
        <DeliveriesListItem delivery={delivery} key={`${delivery.id}-${i}`} />
      ))}
    </div>
  )
}
