import { time } from '$root/utils/time'
import { router } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'
import { useMemo, useState } from 'react'
import { ProblemDelivery } from '$components/Mobile/ProblemDelivery'
import { ContactType } from '$root/utils/constants/clients'
import {
  OrderStatus,
  OrderStatusVerbosed,
} from '$lib/orders/utils/order-status-const'
import EffectorContextProvider from '$components/UI/React/EffectorContextProvider'
import { InvoiceMobile } from '$components/Clients/Invoices/Invoice'
import { NoResults } from '$components/UI/DataGrid/NoResults'
import InvoiceList from '$components/Clients/Invoices/InvoiceList'
import { EffectorServices } from '$root/utils/effector/effector-services'
import { InvoiceListStore } from '$lib/invoices/new-effector/invoice-list-new-store'
import { useDateTime } from '$hooks/useDateTime'
import InvoicePayBtn from '$components/Clients/Invoices/InvoicePayBtn'
import { useModals } from '$hooks/useModals'
import { DeliveryActionsStore } from '$lib/deliveries/effector/delivery-actions-store'
import { onPostSended } from '$models/page'
import { PaySystem } from '$root/utils/constants/payment'
import { formatPhone } from '$root/utils/functions'

export const Delivery = ({ delivery }) => {
  const { openModal, closeModal } = useModals()

  const deliveryActions = EffectorServices.getService({
    service: new DeliveryActionsStore({
      persistKey: `courier-delivery-actions-${delivery.id}`,
      delivery,
    }),
    options: {
      openModal,
    },
  })

  const { dateToSaveFormat } = useDateTime()

  const invoices = useMemo(() => {
    return [delivery.invoice, ...delivery.client.invoices] || []
  }, [delivery.invoice, delivery.client.invoices])

  const [isProblem] = useState(false)
  const invoicesService = EffectorServices.getService({
    service: new InvoiceListStore({
      invoices,
      persistKey: `delivery-${delivery.id}-invoices-list`,
    }),
    options: {
      dateToSaveFormat,
    },
  })

  invoicesService.useContainer()
  invoicesService.checkOnUpdatedInvoices(invoices)

  const deliveryHasProblem = delivery.status === OrderStatus.Problem
  const deliveryCompleted = delivery.status === OrderStatus.Done

  const addressPoint = useMemo(() => {
    const coordinates = (delivery.point && delivery.point.coordinates) || null

    if (coordinates && _.isArray(coordinates) && coordinates.length > 1) {
      return `yandexnavi://build_route_on_map?lat_to=${coordinates[1]}&lon_to=${coordinates[0]}`
    }
  }, [])

  const success = (givenData) => {
    router.post(
      route(Routes.mobile.delivery_done),
      { orders: [givenData] },
      {
        async onSuccess() {
          onPostSended({ postRoute: Routes.mobile.delivery_done })
          router.get(
            route(Routes.mobile.detail, { id: delivery.id, success: 1 }),
          )
          await closeModal()
        },
      },
    )
  }

  const problem = (givenData) => {
    router.post(
      route(Routes.mobile.delivery_cancel),
      { orders: [givenData] },
      {
        async onSuccess() {
          onPostSended({ postRoute: Routes.mobile.delivery_cancel })
          router.get(
            route(Routes.mobile.detail, { id: delivery.id, problem: 1 }),
          )
          await closeModal()
        },
      },
    )
  }

  const clientPhone = useMemo(() => {
    let phone = null
    if (delivery.client.contacts) {
      const contact = delivery.client.contacts.find(
        (contact) => contact.contact_type_code === ContactType.Phone,
      )
      if (contact) {
        phone = contact.value
      }
    }
    return phone
  }, [delivery])

  const saveInvoice = (invoiceService) => {
    const formService = invoiceService.services.formService
    router.post(route(Routes.mobile.saveInvoice), formService.sendingInvoice)
  }

  return (
    <>
      {(!isProblem && (
        <div className="mobile-wrapper__body">
          <div className="mobile-client mobile-client--started open">
            <div className="mobile-client__btn">
              <p className="mobile-client__name">
                {(delivery.client && delivery.client.full_name) ||
                  (delivery.client && delivery.client.name) ||
                  ''}
                {delivery.time !== null && (
                  <span className="mobile-client__time">
                    {time.convertTimeByTimeString(delivery.time)}
                  </span>
                )}
              </p>
              <p>Статус: {OrderStatusVerbosed[delivery.status]}</p>
              <p className="mobile-client__address">
                {delivery.address || '-'}
              </p>
            </div>
            <div className="mobile-client__body">
              {clientPhone && (
                <a
                  className="mobile-client__phone"
                  href={
                    (delivery.client && `tel:${clientPhone}`) ||
                    'javascript:void(0)'
                  }
                >
                  {formatPhone(clientPhone)}
                </a>
              )}

              {delivery.status_comment && (
                <p className="mobile-client__comment">
                  Причина статуса: {delivery.status_comment}
                </p>
              )}

              {delivery.comment && (
                <p className="mobile-client__comment">
                  Комментарий: {delivery.comment}
                </p>
              )}

              <div className="mobile-client__rations">
                {delivery.rations &&
                  delivery.rations.map((ration, i) => (
                    <div className="mobile-client__ration" key={`${ration.id}-${i}`}>
                      {ration.name || ration.ration_name}
                    </div>
                  ))}
              </div>

              <EffectorContextProvider services={{ invoicesService }}>
                <InvoiceList
                  invoices={
                    <>
                      {(invoicesService.store.invoices.length > 0 &&
                        invoicesService.store.invoices.map((invoiceService) => (
                          <EffectorContextProvider
                            services={{ invoiceService }}
                            key={invoiceService.persistKey}
                          >
                            <InvoiceMobile
                              headerActions={({ onPay }) =>
                                invoiceService.isMayPayed && (
                                  <InvoicePayBtn
                                    onPay={onPay}
                                    route={Routes.mobile.payInvoice}
                                  />
                                )
                              }
                              actions={
                                <button
                                  className="btn btn__filled btn--blue"
                                  onClick={() => saveInvoice(invoiceService)}
                                >
                                  Сохранить
                                </button>
                              }
                              paySystems={[PaySystem.Cash, PaySystem.Terminal]}
                            />
                          </EffectorContextProvider>
                        ))) || <NoResults title={false} text="Нет счетов" />}
                    </>
                  }
                />
              </EffectorContextProvider>

              {addressPoint && (
                <div className="mobile-client__row">
                  <a
                    target="_blank"
                    className="btn btn__bordered btn--blue"
                    href={addressPoint}
                    rel="noreferrer"
                  >
                    Построить маршрут
                  </a>
                </div>
              )}

              {!deliveryHasProblem && !deliveryCompleted && (
                <div className="mobile-client__row mobile-client__row--two-col">
                  <button
                    className="btn btn__filled btn--blue"
                    onClick={() => deliveryActions.onGiven(success)}
                  >
                    Доставлено
                  </button>
                  <button
                    className="btn btn__filled btn--red"
                    onClick={() => deliveryActions.onProblem(problem)}
                  >
                    Не доставлено
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )) || <ProblemDelivery delivery={delivery} />}
    </>
  )
}
