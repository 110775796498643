import React from 'react'
import { MainLayout } from '$components/UI/Layouts/MainLayout'
import { PageHeader } from '$components/Leads/PageHeader'
import { LeadsStatusedDataGrid } from '$components/Leads/Statused/DataGrid/LeadsStatusedDataGrid'
import { Routes } from '$root/utils/constants/routes'
import { resetInactiveOrdersDates } from '$lib/clients/store'
import { useGlobalStore } from '$lib/page/provider'
import { ClientsOrdersRouteRegExp } from '$root/config/root-routes'
import { observer } from 'mobx-react'
import { resetAllOrdersDeliveryType } from '$lib/clients/plan-order-delivery-types-store'

export const Leads = observer(
  ({ list, client, detail, orders, transactions, invoices }) => {
    const { pageSelfCleans } = useGlobalStore()

    const clientRoutes = Routes.leads.clients

    pageSelfCleans.addCleaning(ClientsOrdersRouteRegExp, () => {
      resetInactiveOrdersDates()
      resetAllOrdersDeliveryType()
    })

    return (
      <LeadsStatusedDataGrid
        data={list}
        client={client}
        detail={detail}
        orders={orders}
        transactions={transactions}
        invoices={invoices}
        routes={clientRoutes}
      >
        <LeadsStatusedDataGrid.Header>
          <LeadsStatusedDataGrid.Header.Tabs />
          <LeadsStatusedDataGrid.Header.Filters />
        </LeadsStatusedDataGrid.Header>
        <LeadsStatusedDataGrid.Body routes={clientRoutes} />
        <LeadsStatusedDataGrid.Footer />
      </LeadsStatusedDataGrid>
    )
  },
)

Leads.layout = (page) => <MainLayout PageHeader={PageHeader}>{page}</MainLayout>
