import React from 'react'

export const TableDataGridHead = ({ data }) => {
  const { columns } = data

  return (
    <thead>
      <tr>
        {columns.map((column, i) => {
          return <th key={`${column.name}-${i}`}>{column.title}</th>
        })}
      </tr>
    </thead>
  )
}
