import clsx from 'clsx'
import { useDateTime } from '$hooks/useDateTime'
import { usePage } from '@inertiajs/react'
import { formatPhone } from '$root/utils/functions'

export const ListItem = ({ client, onClick }) => {
  const { enums } = usePage().props
  const { date } = useDateTime()
  return (
    <button
      className={clsx('clients__item js-clients__item', {
        [`clients__item--${client && client.status}`]: client && client.status,
      })}
      onClick={() => onClick(client.id)}
    >
      <span className="clients__item-contacts">
        <span className="clients__item-name">{client.full_name || '<>'}</span>
        <span className="clients__item-phone">
          {client.default_phone
            ? formatPhone(client.default_phone) || client.default_phone
            : ''}
        </span>
        <span className="clients__item-phone">
          {enums.clientTypes[client.type] || ''}{' '}
          {formatPhone(client.phone) || ''}
        </span>
      </span>
      <span className="clients__item-info">
        <span className="clients__item-balance">
          <span
            className={clsx('clients__item-money', {
              'clients__item-money--good': client.balance && client.balance > 0,
              'clients__item-money--bad': client.balance && client.balance < 0,
            })}
          >
            {client.balance || 0} ₽
          </span>
          <span className="clients__item-reward">
            {client.bonus_balance || 0} Бв
          </span>
        </span>
        <span className="clients__item-delivery">
          <div>
            Следующая доставка на{' '}
            <span>
              {(client.next_order_date && date(client.next_order_date)) || '-'}
            </span>
          </div>
          <div>
            Последняя доставка на{' '}
            <span>
              {(client.latest_order_date && date(client.latest_order_date)) ||
                '-'}
            </span>
          </div>
        </span>
      </span>
      <span>
        {client.rations &&
          client.rations.map((ration, i) => <div key={`${ration.id}-${i}`}>{ration.name}</div>)}
      </span>
    </button>
  )
}
