import { $dataGrid, dataGridIsChanged } from '$models/dataGrid'
import { useStore } from 'effector-react'
import clsx from 'clsx'

export const DateTypeKitchenFilter = ({
  data,
  field,
  title = 'Тип даты',
  variants = [],
}) => {
  const { filter } = useStore($dataGrid)

  const updateFilter = (value) => {
    dataGridIsChanged({
      filter: !checkActive(value)
        ? {
            [field]: value,
          }
        : undefined,
    })
  }

  const checkActive = (value) => {
    return filter && filter[field] && filter[field] === value
  }

  const FilterItem = ({ item, className }) => {
    return (
      <button
        className={clsx('contact-within__date', className)}
        onClick={() => {
          updateFilter(item.value)
        }}
      >
        {item.name}
      </button>
    )
  }

  return (
    <div className="contact-within">
      <p className="contact-within__text">{title}:</p>
      {variants.map((variant, i) => (
        <FilterItem
          item={variant}
          className={{
            active: checkActive(variant.value),
            'no-remove-icon': variant.showRemoveIcon === false,
          }}
          key={`${variant.value}-${i}`}
        />
      ))}
    </div>
  )
}
