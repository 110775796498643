import { onCheckRation } from '$components/Settings/Leads/ProductsTab/models'
import { usePage } from '@inertiajs/react'
import { router } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'
import { onDeleteSended } from '$models/page'
import { CreateUpdateRationModal } from '$components/Settings/Leads/ProductsTab/Modals/CreateUpdateRationModal'
import { useModals } from '$hooks/useModals'
import { getEnumTitleById } from '$root/utils/enums'
import { RationMultipliers } from '$components/Settings/Leads/ProductsTab/RationTiles/RationMultipliers'

export const Ration = ({ ration }) => {
  const { enums } = usePage().props
  const { openModal } = useModals()

  const { multipliers } = enums

  const showEditRationModal = (ration) => {
    openModal('EditProduct', CreateUpdateRationModal, {
      title: 'Изменить рацион',
      ration,
      multipliers: enums.multipliers || [],
      classes: { wrapper: 'modal--no-overflow' },
    })
  }

  const removeRation = (ration_id) => {
    router.delete(route(Routes.settings.leads.rations.remove_ration), {
      data: { id: ration_id },
      onSuccess() {
        onDeleteSended()
      },
    })
  }

  return (
    <div className="products__item active">
      <div className="products__head">
        <p className="products__title">
          <span>
            {ration.name || '<>'}
            &nbsp;
            {ration.multiplier_id && (
              <span>
                ({getEnumTitleById(enums.multipliers, ration.multiplier_id)})
              </span>
            )}
          </span>
          {ration.price && <span className="price">{ration.price} ₽</span>}
        </p>
        <label className="input-chckbx">
          <input
            type="checkbox"
            defaultChecked={false}
            onChange={(ev) => {
              onCheckRation({ ration, isChecked: ev.target.checked })
            }}
          />
          <span></span>
        </label>
        <button
          className="products__btn-edit"
          title="Редактировать"
          onClick={() => showEditRationModal(ration)}
        >
          Редактировать
        </button>

        <button
          className="products__btn-remove"
          title="Удалить"
          onClick={() => removeRation(ration.id)}
        >
          Удалить
        </button>
      </div>
      <div className="products__body">
        <table>
          <tbody>
            <tr>
              <th>Порция</th>
              {multipliers &&
                multipliers.map((multiplier, i) => (
                  <th key={`${multiplier.id}-${i}`}>{multiplier.name}</th>
                ))}
            </tr>
            <RationMultipliers ration={ration} multipliers={multipliers} />
          </tbody>
        </table>
      </div>
    </div>
  )
}
