import { ProductPrices } from '$components/Settings/Leads/ProductsTab/Modals/ChangeProductPrices/ProductPrices'
import { useCallback, useEffect, useState } from 'react'

export const ProductsWithPrices = ({ products, multipliers, onUpdate }) => {
  const [prices, setPrices] = useState(
    products.reduce(
      (prices, product) => [
        ...prices,
        ...(product.prices && Array.isArray(product.prices)
          ? product.prices.map((productPrice) => ({
              product_id: product.id,
              multiplier_id: productPrice.multiplier_id,
              price: productPrice.price,
            }))
          : []),
      ],
      [],
    ),
  )

  useEffect(() => onUpdate(prices), [prices])

  const buildPriceItem = (product_id, multiplier_id, price) => ({
    product_id: product_id,
    multiplier_id: multiplier_id,
    price: price,
  })

  const onClearPrices = (product) => {
    for (const productPriceItem of prices.filter(
      (priceItem) => priceItem.product_id === product.id,
    )) {
      productPriceItem.price = 0
    }
    setPrices([...prices])
  }

  const onChangePriceItem = (product_id, multiplier_id, price) => {
    const _prices = [...prices]
    const newPrice = price || 0

    const index = _prices.findIndex(
      (priceItem) =>
        priceItem.product_id === product_id &&
        priceItem.multiplier_id === multiplier_id,
    )

    if (index !== -1) {
      _prices.splice(index, 1, { ..._prices[index], price: newPrice })
    } else {
      _prices.push(buildPriceItem(product_id, multiplier_id, newPrice))
    }
    setPrices(_prices)
  }

  const getProductPrices = useCallback(
    (productId) => {
      return prices.filter((priceIter) => priceIter.product_id === productId)
    },
    [products, prices],
  )

  return products.map((product, i) => (
    <tr key={`${product.id}-${i}`}>
      <td>{product.name || '<>'}</td>
      <ProductPrices
        multipliers={multipliers}
        product_id={product.id}
        prices={getProductPrices(product.id)}
        onChangePriceItem={onChangePriceItem}
      />
      <td>
        <button
          className="change-price__btn-delete"
          onClick={(ev) => {
            onClearPrices(product)
          }}
        >
          Удалить
        </button>
      </td>
    </tr>
  ))
}
