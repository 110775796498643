import { router, usePage } from '@inertiajs/react'
import { useCallback, useMemo, useState } from 'react'
import { HeaderMultipliersList } from '$components/Settings/Leads/ProductsTab/Modals/ChangeProductPrices/HeaderMultipliersList'
import { ProductsWithPrices } from '$components/Settings/Leads/ProductsTab/Modals/ChangeProductPrices/ProductsWithPrices'
import { Routes } from '$root/utils/constants/routes'
import { onPostSended } from '$models/page'

export const ChangeProductPrices = () => {
  const { enums } = usePage().props
  const multipliers = useMemo(() => (enums && enums.multipliers) || [], [enums])
  const products = useMemo(
    () => (enums && _.values(enums.products)) || [],
    [enums],
  )
  const [prices, updatePrices] = useState({ prices: [] })

  const sendUpdatedPrices = useCallback(() => {
    if (products.length === 0) {
      alert('Товаров нет, добавьте товары')
      return
    }
    router.post(
      route(Routes.settings.leads.rations.save_prices),
      { prices },
      {
        onSuccess() {
          onPostSended()
        },
      },
    )
  }, [prices, products])

  return (
    <>
      <div className="modal-right__section">
        <div className="change-price">
          <table>
            <tbody>
              <HeaderMultipliersList multipliers={multipliers} />
              <ProductsWithPrices
                products={products}
                multipliers={multipliers}
                onUpdate={updatePrices}
              />
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal-right__section">
        <button
          className="btn btn__filled btn--blue"
          onClick={sendUpdatedPrices}
        >
          Сохранить
        </button>
      </div>
    </>
  )
}
